import { LocalStorageService } from '@/core/storage/storage.service';
import { LocalStorageKeys } from '@/project/localStorageKeys';
import { UserStore } from '@/core/store/user/user.store';
import { BusinessEntitySettingsViewObject, CustomerViewObject } from '@/api';
import bus from '@/core/bus';
import { ComputedRef, computed } from 'vue';

interface State extends Record<string, unknown> {
}

class BusinessUserStore extends UserStore<State> {
    constructor() {
        super(false);

        bus.on('BusinessEntitySettingsUpdated', (settings: BusinessEntitySettingsViewObject) => {
            if (this.state.identity.user) 
                this.state.identity.user.businessEntity.settings = settings;
        });
    }

    public get isAdministrator(): ComputedRef<boolean> {
        return computed(() => false);
    }

    public get isModerator(): ComputedRef<boolean> {
        return computed(() => false);
    }

    public get isCarrier(): ComputedRef<boolean> {
        return computed(() => false);
    }

    public get customer(): ComputedRef<CustomerViewObject> {
        return computed(() => this.state.identity.user!.customer);
    }
    
    protected override data() {
        return {
            identity: {
                user: undefined,
                token: LocalStorageService.getItem(LocalStorageKeys.TOKEN),
            },
        };
    }

    public get isWhatsAppInstanceAuthorized() {
        return computed(() => false);
    }
}

export const userStore = new BusinessUserStore();
